import {graphql, Link} from 'gatsby';
import React from 'react';

import {getFlagEmoji, MongoPassport} from '@travelfreedom/shared';

import {
  buildMapDataFromVisaLists,
  Layout,
  PassportImage,
  PassportImageData,
  ReportErrorButton,
  SEO,
  TableListEntry,
  WorldMap,
} from '../components';
import {Passport, VisaLists} from '../library/common';

interface PassportProps {
  data: {
    mongodbTravelfreedomPassports: MongoPassport;
    contentfulPassport: {
      images: {
        localFile: {
          childImageSharp: PassportImageData;
        }[];
      };
    };
  };
}

export const PassportTemplate = ({data}: PassportProps): JSX.Element => {
  const passport = Passport.fromJson(data.mongodbTravelfreedomPassports);

  return (
    <Layout>
      <SEO
        title={`${getFlagEmoji(passport.code)} ${Passport.adjectiveName(passport)}`}
        pathname={Passport.hrefPath(passport)}
      />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">
        {getFlagEmoji(passport.code)} {Passport.adjectiveName(passport)}
      </h1>
      <div className="grid lg:grid-cols-2 md:gap-4 max-w-7xl mx-auto">
        <div className="flex p-2 border rounded border-gray-300 bg-white min-w-0">
          {data.contentfulPassport?.images && (
            <PassportImage
              smallImage={data.contentfulPassport.images[0].localFile.childImageSharp.smallImage}
              largeImage={data.contentfulPassport.images[0].localFile.childImageSharp.largeImage}
              passport={passport}
              format="VERTICAL"
            />
          )}
          <div className="inline-flex flex-col">
            <div className="my-1">
              {Passport.adjectiveName(passport)} lets you travel to{' '}
              {VisaLists.travelfreedom(passport)} countries/territories without a prior visa.
            </div>
            <div className="my-1">
              It is ranked <Link to="/ranking">#{passport.ranking.rank} in the world.</Link>
            </div>
            <div className="my-1">
              <Link to={`/compare?compare=${passport.globalCode.toLowerCase()}`}>Compare</Link> this
              passport to others.
            </div>
          </div>
        </div>
        <div className="">
          <div className="inline-flex flex-wrap py-2">
            <Link
              to={`/compare?compare=${passport.globalCode.toLowerCase()}`}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-2"
            >
              Compare
            </Link>
            <ReportErrorButton />
          </div>
        </div>
      </div>

      <div className="bg-white my-2 max-w-7xl mx-auto">
        <WorldMap worldMapData={buildMapDataFromVisaLists(passport)} showLegend />
      </div>

      {/* <GoogleAd /> */}

      {passport.visafreeList.length > 0 && (
        <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
          <thead>
            <tr className="bg-green-600 text-white">
              <th colSpan={2} className="p-2">
                Visa-Free/Visa-on-arrival
              </th>
            </tr>
          </thead>
          <tbody className="bg-green-100 divide-y divide-gray-200">
            {passport.visafreeList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={passport.conditions[code]}
                targetType="destination"
              />
            ))}
          </tbody>
        </table>
      )}
      {passport.etaList.length > 0 && (
        <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
          <thead>
            <tr className="bg-yellow-600 text-white">
              <th colSpan={2} className="p-2">
                Electronic Authorization
              </th>
            </tr>
          </thead>
          <tbody className="bg-yellow-100 divide-y divide-gray-200">
            {passport.etaList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={passport.conditions[code]}
                targetType="destination"
              />
            ))}
          </tbody>
        </table>
      )}
      {passport.visapriorList.length > 0 && (
        <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
          <thead>
            <tr className="bg-red-600 text-white">
              <th colSpan={2} className="p-2">
                Visa required prior to arrival
              </th>
            </tr>
          </thead>
          <tbody className="bg-red-200 divide-y divide-gray-200">
            {passport.visapriorList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={passport.conditions[code]}
                targetType="destination"
              />
            ))}
          </tbody>
        </table>
      )}
      {passport.bannedList.length > 0 && (
        <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
          <thead>
            <tr className="bg-gray-600 text-white">
              <th colSpan={2} className="p-2">
                Entry denied
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-300 divide-y divide-gray-200">
            {passport.bannedList.map((code) => (
              <TableListEntry
                key={code}
                code={code}
                condition={passport.conditions[code]}
                targetType="destination"
              />
            ))}
          </tbody>
        </table>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!, $globalCode: String!) {
    mongodbTravelfreedomPassports(fields: {slug: {eq: $slug}}) {
      globalCode
      name
      adjective
      ranking {
        rank
        rankArea
        rankPopulation
        travelfreedom
        travelfreedomArea
        travelfreedomPopulation
      }
      visaList {
        visaprior
        visafree
        eta
        banned
      }
      conditions {
        key
        value
      }
    }
    contentfulPassport(globalCode: {eq: $globalCode}) {
      images {
        localFile {
          childImageSharp {
            smallImage: gatsbyImageData(width: 110, height: 150)
            largeImage: gatsbyImageData
          }
        }
      }
    }
  }
`;

export default PassportTemplate;
